import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import { JobsCards, VettedProfessionalsCards } from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionArticle.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    listings,
    proListings,
    proLastedListings,
    isAuthenticated,
    companyLastedListings,
    currentUser,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;


  function getCombinedListings(proListings, proLastedListings) {
    // Ensure that proListings and proLastedListings are valid arrays
    proListings = Array.isArray(proListings) ? proListings : [];
    proLastedListings = Array.isArray(proLastedListings) ? proLastedListings : [];

    // Start with proListings
    let combinedListings = [...proListings];

    // Check if we have fewer than 4 items in combinedListings
    if (combinedListings.length < 4) {
      // Calculate how many more items we need
      const neededItems = 4 - combinedListings.length;

      // Add the needed items from proLastedListings
      combinedListings = combinedListings.concat(proLastedListings.slice(0, neededItems));
    }

    // Return only the first 4 items
    return combinedListings.slice(0, 4);
  }

  // Example usage
  const proListingsToShow = getCombinedListings(proListings, proLastedListings);

  function getCombinedCompanyListings(listings, companyLastedListings) {
    // Ensure that listings and companyLastedListings are valid arrays
    listings = Array.isArray(listings) ? listings : [];
    companyLastedListings = Array.isArray(companyLastedListings) ? companyLastedListings : [];

    // Start with listings
    let combinedListings = [...listings];

    // Check if we have fewer than 4 items in combinedListings
    if (combinedListings.length < 4) {
      // Calculate how many more items we need
      const neededItems = 4 - combinedListings.length;

      // Add the needed items from companyLastedListings
      combinedListings = combinedListings.concat(companyLastedListings.slice(0, neededItems));
    }

    // Return only the first 4 items
    return combinedListings.slice(0, 4);
  }

  // Example usage
  const companyListingsToShow = getCombinedCompanyListings(listings, companyLastedListings);
  const userType = currentUser?.attributes?.profile?.publicData?.userType || [];
  const isMultiRole = userType.length === 2;

  const blockIds = blocks.map((block) => block.blockId);
  const jobsCard = blockIds.includes("jobs_card")
  const vetted_professionals = blockIds.includes("professionals_card");

  let showProListing = false;
  let showComapnyListing = false;

  if (isMultiRole) {
    showProListing = true;
    showComapnyListing = true
  } else if (userType[0]?.roleName === "company") {
    showComapnyListing = true
  } else if (userType[0]?.roleName === "pro") {
    showProListing = true;
  }

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {/* {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null} */}
      {(jobsCard && showProListing) || isAuthenticated == false ?
        <>
          <header className={defaultClasses.sectionDetails}> <Field data={title} className={defaultClasses.title} options={fieldOptions} /></header>

          <JobsCards
            listings={companyListingsToShow}
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
          />
        </>
        : (vetted_professionals && showComapnyListing) || isAuthenticated == false ?
          <>

            <header className={defaultClasses.sectionDetails}> <Field data={title} className={defaultClasses.title} options={fieldOptions} /></header>

            <VettedProfessionalsCards proListings={proListingsToShow}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser} />
          </>
          : hasBlocks ? (
            <div
              className={classNames(defaultClasses.blockContainer, css.articleMain, {
                [css.noSidePaddings]: isInsideContainer,
              })}
            >
              <BlockBuilder
                blocks={blocks}
                sectionId={sectionId}
                ctaButtonClass={defaultClasses.ctaButton}
                options={options}
              />
            </div>
          ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};
export default SectionArticle;
